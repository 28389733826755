import React, { useEffect, useState } from "react";
import Header from "./header";
import { getMGBSolutionAccount, changePassword } from "../../routes/auth";

const Profile = () => {
  const [accountData, setAccountData] = useState(null);
  const [currency, setCurrency] = useState("EUR");
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const data = await getMGBSolutionAccount();
        setAccountData(data);
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

    fetchAccountData();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handlePasswordToggle = () => {
    setShowPasswordFields(!showPasswordFields);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    // Check if the new password meets the minimum length requirement
    if (passwordData.newPassword.length < 6) {
      alert("Votre nouveau mot de passe ne remplis pas les critères de sécurité adéquat.");
      return;
    }

    // Check if the new password and confirm password match
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert("Votre mot de passe et votre mot de passe de confirmation sont différents");
      return;
    }

    try {
      // Attempt to change the password
      await changePassword(passwordData.oldPassword, passwordData.newPassword);
      setShowPasswordFields(false);
      alert("Mot de passe changé avec succès");
    } catch (error) {
      // Check if the error message contains "Old password is incorrect"
      if (
        error.message &&
        error.message.includes("Old password is incorrect")
      ) {
        alert("Ancien mot de passe incorrect");
      }
      // If the error message does not contain "Old password is incorrect", do nothing
    }
  };

  const styles = {
    profileContainer: {
      backgroundColor: "#fff",
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
      padding: "20px",
      maxWidth: "90%",
      margin: "20px auto",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    profileHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#f7f7f7",
      padding: "15px 20px",
      borderRadius: "8px 8px 0 0",
    },
    profileName: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#004aad",
    },
    usernameContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0px",
    },
    profileUsername: {
      marginRight: "15px",
      marginTop: "2px",
      fontSize: "1.5rem",
      fontWeight: "500",
      color: "#004aad",
    },
    icon: {
      color: "#004aad",
      fontSize: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    profileDetails: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
    },
    profileInfo: {
      flex: 1,
      padding: "0 10px",
    },
    profileInfoLabel: {
      marginLeft: "5px",
      margin: "5px 0",
      fontWeight: "400",
      color: "black",
    },
    profileInfoText: {
      marginLeft: "2px",
      marginTop: "11px",
      fontWeight: "400",
    },
    parameterInfoLabel: {
      marginLeft: "5px",
      marginBottom: "15px",
      margin: "5px 0",
      fontWeight: "400",
      color: "black",
      textDecoration: "underline",
    },
    containerText: {
      height: "35px",
      maxWidth: "700px",
      backgroundColor: "rgb(237, 242, 247)",
      borderRadius: "8px",
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    disclaimerLink: {
      color: "#004aad",
      textDecoration: "none",
      cursor: "pointer",
    },
    disclaimerLinkHover: {
      textDecoration: "underline",
      color: "black",
    },
    disclaimerInfoText: {
      margin: "5px 0",
      color: "black",
    },
    passwordToggleBtn: {
      color: "red",
      backgroundColor: "#f3f4f6",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "5px 10px",
      marginBottom: "15px",
      cursor: "pointer",
    },
    passwordConfirmBtn: {
      padding: "10px 20px",
      backgroundColor: "#fff",
      color: "red",
      border: "1px solid red",
      borderRadius: "5px",
      cursor: "pointer",
      marginTop: "10px",
    },
    formField: {
      margin: "10px 0",
    },
    inputField: {
      width: "190px",
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid black",
      marginBottom: "20px",
      color: "black",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#004aad",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      marginTop: "10px",
    },
    helperText: {
      fontSize: "0.9rem",
      color: "#6b7280",
      marginTop: "5px",
    },
    learnMoreLink: {
      color: "#004aad",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };

  return (
    <div>
      <Header />
      <div style={styles.profileContainer}>
        <div style={styles.profileHeader}>
          <div style={styles.profileName}>Compte client</div>
          <div style={styles.usernameContainer}>
            <div style={styles.profileUsername}>{accountData?.fullname}</div>
            <div style={styles.icon}>
              <ion-icon name="person-outline"></ion-icon>
            </div>
          </div>
        </div>
        {accountData ? (
          <div style={styles.profileDetails}>
            <div style={styles.profileInfo}>
              <div>
                <p style={styles.profileInfoLabel}>Id du compte</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>{accountData.myfxbookID}</p>
                </div>
              </div>

              <div>
                <p style={styles.profileInfoLabel}>Identifiant MetaTrader</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>{accountData.name}</p>
                </div>
              </div>
              <div>
                <p style={styles.profileInfoLabel}>Numéro de téléphone</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>{accountData.phone}</p>
                </div>
              </div>
            </div>
            <div style={styles.profileInfo}>
              <div>
                <p style={styles.profileInfoLabel}>Type de compte</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>
                    {accountData.demo ? "Demo" : "Actif"}
                  </p>
                </div>
              </div>
              <div>
                <p style={styles.profileInfoLabel}>Date de création</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>
                    {formatDate(accountData.creationDate)}
                  </p>
                </div>
              </div>
              <div>
                <p style={styles.profileInfoLabel}>Adresse mail</p>
                <div style={styles.containerText}>
                  <p style={styles.profileInfoText}>{accountData.email}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading account data...</p>
        )}
      </div>
      <div style={styles.profileContainer}>
        <div style={styles.profileHeader}>
          <div style={styles.profileName}>Facturation</div>
          <div style={styles.icon}>
            <ion-icon name="cash-outline"></ion-icon>
          </div>
        </div>
        <div style={styles.profileDetails}>
          <div style={styles.disclaimer}>
            <p style={styles.disclaimerInfoText}>
              Bientôt disponible. Restez au courant de nos dernières nouveautés
              sur notre compte X{" "}
              <a
                href="https://x.com/mgbsolution"
                style={styles.disclaimerLink}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration =
                    styles.disclaimerLinkHover.textDecoration)
                }
                onMouseLeave={(e) =>
                  (e.target.style.textDecoration =
                    styles.disclaimerLink.textDecoration)
                }
              >
                mgbsolution
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div style={styles.profileContainer}>
        <div style={styles.profileHeader}>
          <div style={styles.profileName}>Paramètres du compte</div>
          <div style={styles.icon}>
            <ion-icon name="key-outline"></ion-icon>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          {/* Currency Selection */}
          <div style={styles.formField}>
            <p style={styles.parameterInfoLabel}>Sélection de la devise</p>
            <select
              value={currency}
              onChange={handleCurrencyChange}
              style={styles.inputField}
            >
              <option value="EUR">Euro (EUR)</option>
              <option value="USD">Dollar (USD)</option>
            </select>
          </div>
          <div style={styles.passwordHeader}>
            <p style={styles.parameterInfoLabel}>Mot de passe</p>
            <button
              onClick={handlePasswordToggle}
              style={styles.passwordToggleBtn}
            >
              {showPasswordFields ? "Cacher" : "Changer de mot de passe"}
            </button>
          </div>
          {showPasswordFields ? (
            <div>
              <div>
                <input
                  type="password"
                  name="oldPassword"
                  placeholder="Ancien mot de passe"
                  value={passwordData.oldPassword}
                  onChange={handlePasswordChange}
                  style={styles.inputField}
                />
              </div>
              <div>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Nouveau mot de passe"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  style={styles.inputField}
                />
              </div>
              <div>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirmer le mot de passe"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  style={styles.inputField}
                />
              </div>
              <p style={styles.helperText}>
                Assurez-vous qu'il contient au moins 15 caractères OU au moins 8
                caractères, dont un chiffre et une lettre minuscule.
                <a
                  href="https://pro.orange.fr/lemag/5-conseils-pour-bien-choisir-son-mot-de-passe-CNT000001uWUIe.html"
                  style={styles.learnMoreLink}
                >
                  {" "}
                  En savoir plus
                </a>
                .
              </p>
              <button
                style={styles.passwordConfirmBtn}
                onClick={handlePasswordSubmit}
              >
                Mettre le mot de passe à jour
              </button>
            </div>
          ) : (
            <p style={styles.helperText}>
              Renforcez votre compte en vous assurant que votre mot de passe est
              fort.
              <a
                href="https://pro.orange.fr/lemag/5-conseils-pour-bien-choisir-son-mot-de-passe-CNT000001uWUIe.html"
                style={styles.learnMoreLink}
              >
                {" "}
                En savoir plus sur la création d'un mot de passe fort
              </a>
              .
            </p>
          )}
        </div>
      </div>
      <div style={{ paddingTop: "5px" }}></div>
    </div>
  );
};

export default Profile;
