import React, { useEffect, useState, useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { getTradingHistory } from "../../routes/trading";
import Header from "./header";

Chart.register(ArcElement, Tooltip, Legend);

const Contracts = () => {
  const [profitData, setProfitData] = useState([]);
  const [averageTradeData, setAverageTradeData] = useState([]);
  const [tradeCountData, setTradeCountData] = useState([]);
  const [symbolList, setSymbolList] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("ALL");
  const [advancedStats, setAdvancedStats] = useState({
    totalLongTrades: 0,
    totalLongPL: 0,
    averageLongPL: 0,
    minLongPL: 0,
    maxLongPL: 0,
    longWinPercentage: 0,
    totalShortTrades: 0,
    totalShortPL: 0,
    averageShortPL: 0,
    minShortPL: 0,
    maxShortPL: 0,
    shortWinPercentage: 0,
  });

  const chartRefs = useRef({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTradingHistory();

      const symbols = [...new Set(data.map((item) => item.symbol))];
      setSymbolList(["ALL", ...symbols]);

      const filteredData = data.filter((item) => item.profit > 0);
      const profit = filteredData.reduce((acc, item) => {
        acc[item.symbol] = (acc[item.symbol] || 0) + item.profit;
        return acc;
      }, {});
      setProfitData(
        Object.entries(profit).map(([symbol, value]) => ({ symbol, value }))
      );

      // Calculate average trade duration in days per asset
      const durations = data.reduce((acc, item) => {
        const durationInMs = new Date(item.closeTime) - new Date(item.openTime);
        acc[item.symbol] = acc[item.symbol] || [];
        acc[item.symbol].push(durationInMs);
        return acc;
      }, {});

      setAverageTradeData(
        Object.entries(durations).map(([symbol, values]) => ({
          symbol,
          value: values.reduce((a, b) => a + b, 0) / values.length / 86400000, // Convert milliseconds to days
        }))
      );

      const tradeCounts = data.reduce((acc, item) => {
        acc[item.symbol] = (acc[item.symbol] || 0) + 1;
        return acc;
      }, {});
      setTradeCountData(
        Object.entries(tradeCounts).map(([symbol, value]) => ({
          symbol,
          value,
        }))
      );

      // Set initial advanced statistics for "ALL" symbols
      updateAdvancedStats(data, "ALL");
    };

    fetchData();
  }, []);

  const updateAdvancedStats = (data, symbol) => {
    const filteredData =
      symbol === "ALL" ? data : data.filter((item) => item.symbol === symbol);
    const longTrades = filteredData.filter((item) => item.action === "Buy");
    const shortTrades = filteredData.filter((item) => item.action === "Sell");

    const calculateStats = (trades) => {
      const totalPL = trades.reduce((sum, trade) => sum + trade.profit, 0);
      const minPL =
        trades.length > 0
          ? Math.min(...trades.map((trade) => trade.profit))
          : 0;
      const maxPL =
        trades.length > 0
          ? Math.max(...trades.map((trade) => trade.profit))
          : 0;
      const averagePL = trades.length > 0 ? totalPL / trades.length : 0;
      const winPercentage =
        trades.length > 0
          ? (trades.filter((trade) => trade.profit > 0).length /
              trades.length) *
            100
          : 0;

      return { totalPL, minPL, maxPL, averagePL, winPercentage };
    };

    const longStats = calculateStats(longTrades);
    const shortStats = calculateStats(shortTrades);

    setAdvancedStats({
      totalLongTrades: longTrades.length,
      totalLongPL: longStats.totalPL,
      averageLongPL: longStats.averagePL,
      minLongPL: longStats.minPL,
      maxLongPL: longStats.maxPL,
      longWinPercentage: longStats.winPercentage,
      totalShortTrades: shortTrades.length,
      totalShortPL: shortStats.totalPL,
      averageShortPL: shortStats.averagePL,
      minShortPL: shortStats.minPL,
      maxShortPL: shortStats.maxPL,
      shortWinPercentage: shortStats.winPercentage,
    });
  };

  const handleSymbolChange = (event) => {
    const selectedSymbol = event.target.value;
    setSelectedSymbol(selectedSymbol);
    getTradingHistory().then((data) =>
      updateAdvancedStats(data, selectedSymbol)
    );
  };

  const createPieChart = (chartId, data, title, unit) => {
    // Destroy existing chart instance if it exists
    if (chartRefs.current[chartId]) {
      chartRefs.current[chartId].destroy();
    }

    const chartConfig = {
      type: "pie",
      data: {
        labels: data.map((item) => item.symbol),
        datasets: [
          {
            data: title.includes("Nombre de trades")
              ? data.map((item) => item.value)
              : data.map((item) => item.value.toFixed(2)),
            label: title.includes("Profit")
              ? "Profit"
              : title.includes("Durée")
              ? "Durée moyenne"
              : "Nombre de trades",
            backgroundColor: ["#519DE9", "#7CC674", "#73C5C5", "#8481DD"],
            borderColor: "#000000",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw; // This will be the numeric value from the dataset
                return `${value} ${unit}`; // Append the unit to the tooltip label
              },
            },
          },
          title: {
            display: true,
            text: title,
            color: "#000000", // Set title color to black
            font: {
              size: 14,
            },
            padding: {
              top: 20, // Add spacing between the chart and the title
              bottom: 20,
            },
            position: "bottom",
          },
        },
      },
    };

    const ctx = document.getElementById(chartId).getContext("2d");
    chartRefs.current[chartId] = new Chart(ctx, chartConfig);
  };

  useEffect(() => {
    if (profitData.length > 0)
      createPieChart("profitChart", profitData, "Profit par actif", "€");
    if (averageTradeData.length > 0)
      createPieChart(
        "averageTradeChart",
        averageTradeData,
        "Durée moyenne d'un trade par actif",
        "Jours"
      );
    if (tradeCountData.length > 0)
      createPieChart(
        "tradeCountChart",
        tradeCountData,
        "Nombre de trades par actif",
        ""
      );
  }, [profitData, averageTradeData, tradeCountData]);

  return (
    <div>
      <Header />
      <div className="container">
        <div className="chart-card">
          <h4 className="section-title">Aperçu des actifs</h4>
          <div className="pie-chart-container">
            <div className="pie-chart">
              <canvas id="profitChart"></canvas>
            </div>
            <div className="pie-chart">
              <canvas id="averageTradeChart"></canvas>
            </div>
            <div className="pie-chart">
              <canvas id="tradeCountChart"></canvas>
            </div>
          </div>
        </div>
        <div className="stats-card">
          <div className="header-container">
            <h4 className="section-title">Statistiques avancées</h4>
            <select
              id="contractType"
              className="form-select"
              value={selectedSymbol}
              onChange={handleSymbolChange}
            >
              {symbolList.map((symbol, index) => (
                <option key={index} value={symbol}>
                  {symbol}
                </option>
              ))}
            </select>
          </div>
          <div className="stats-row">
            <div className="stats-column">
              <div className="stats-item">
                <p className="stats-label">Total Long Trades:</p>
                <p className="stats-value">{advancedStats.totalLongTrades}</p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Total Long P&L:</p>
                <p className="stats-value">
                  {advancedStats.totalLongPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Average Long P&L:</p>
                <p className="stats-value">
                  {advancedStats.averageLongPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Min Long P&L:</p>
                <p className="stats-value">
                  {advancedStats.minLongPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Max Long P&L:</p>
                <p className="stats-value">
                  {advancedStats.maxLongPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Long Win %:</p>
                <p className="stats-value">
                  {advancedStats.longWinPercentage.toFixed(2)}%
                </p>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="stats-column">
              <div className="stats-item">
                <p className="stats-label">Total Short Trades:</p>
                <p className="stats-value">{advancedStats.totalShortTrades}</p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Total Short P&L:</p>
                <p className="stats-value">
                  {advancedStats.totalShortPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Average Short P&L:</p>
                <p className="stats-value">
                  {advancedStats.averageShortPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Min Short P&L:</p>
                <p className="stats-value">
                  {advancedStats.minShortPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Max Short P&L:</p>
                <p className="stats-value">
                  {advancedStats.maxShortPL.toFixed(2)} €
                </p>
              </div>
              <div className="stats-item">
                <p className="stats-label">Short Win %:</p>
                <p className="stats-value">
                  {advancedStats.shortWinPercentage.toFixed(2)}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          padding: 20px;
        }
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
        }
        .chart-card {
          width: 95%;
          background-color: white;
          margin: 0 auto;
          padding: 20px;
          border-radius: 10px;
          border: 1px solid #ced4da;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .stats-card {
          width: 95%;
          background-color: white;
          margin: 20px auto;
          padding: 30px;
          border-radius: 10px;
          border: 1px solid #ced4da;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .section-title {
          margin-bottom: 20px;
          font-size: 1.5em;
          color: #004aad;
        }
        .pie-chart-container {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .pie-chart {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .vertical-line {
          width: 2px;
          height: 235px;
          background-color: #004aad;
          margin: 0 20px;
        }
        .stats-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .stats-label {
          font-size: 16px;
          color: #333333;
          margin-right: 10px;
        }
        .stats-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .stats-value {
          font-size: 16px;
          color: #333333;
          text-align: right;
        }
        .stats-column {
          display: flex;
          flex-direction: column;
          width: 45%;
        }
        select#contractType {
          margin-bottom: 10px;
          border: 1px solid;
          border-radius: 5px;
          padding: 5px;
          background-color: white;
          outline: none;
        }
      `}</style>
    </div>
  );
};

export default Contracts;
