import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import Header from "./header";
import { getMyfxbookAccount } from "../../routes/auth";
import { getTradingData } from "../../routes/trading";
import dayjs from "dayjs"; // For date handling

const Dashboard = () => {
  const [displayType, setDisplayType] = useState("normal");
  const [accountData, setAccountData] = useState([]);
  const [tradingData, setTradingData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("mois");
  const chartRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);

  // Fetch the Myfxbook account and trading data on component mount
  useEffect(() => {
    const fetchAccountAndTradingData = async () => {
      try {
        const account = await getMyfxbookAccount();
        setAccountData(account); // Store the account data

        const trading = await getTradingData();

        setTradingData(trading); // Store the trading data
      } catch (error) {
        console.error("Error fetching account or trading data:", error);
      }
    };

    fetchAccountAndTradingData();
  }, []);

  // Convert creation date to elapsed time (years, months, days)
  const calculateElapsedTime = (creationDate) => {
    const now = dayjs();
    const createdAt = dayjs(creationDate, "DD/MM/YYYY HH:mm");
    const diffYears = now.diff(createdAt, "year");
    const diffMonths = now.diff(createdAt, "month") % 12;
    const diffDays = now.diff(createdAt, "day") % 30;

    return `${diffYears} ${
      diffYears === 1 ? "année" : "années"
    }, ${diffMonths} ${diffMonths === 1 ? "mois" : "mois"}, ${diffDays} ${
      diffDays === 1 ? "jour" : "jours"
    }`;
  };

  // Calculate averages based on timeframe (day, month, year)
  const calculateAverage = (
    timeframe,
    totalProfit,
    elapsedDays,
    initialBalance
  ) => {
    let daysPerUnit;
    switch (timeframe) {
      case "jour":
        daysPerUnit = 1;
        break;
      case "mois":
        daysPerUnit = 30;
        break;
      case "année":
      default:
        daysPerUnit = 365;
        break;
    }
    const unitsElapsed = elapsedDays / daysPerUnit;

    // Calculate the average percentage change based on the initial balance
    const averagePnLPercentage =
      ((totalProfit / initialBalance) * 100) / unitsElapsed;

    return {
      averageTransactions: (tradingData?.length || 0) / unitsElapsed,
      averagePnL: averagePnLPercentage,
    };
  };

  // Get the elapsed days since account creation
  const getElapsedDays = (creationDate) => {
    const createdAt = dayjs(creationDate, "DD/MM/YYYY HH:mm");
    return dayjs().diff(createdAt, "day");
  };

  // Function to calculate percentage change based on profit values
  function calculateDailyPercentageChange(data) {
    const percentageData = [];

    for (let i = 1; i < data.length; i++) {
      const previousBalance = data[i - 1].balance;
      const currentProfit = data[i].profit;

      const percentageChange = (currentProfit / previousBalance) * 100;
      percentageData.push(percentageChange);
    }

    // Add a null or zero value for the first day since it doesn't have a previous day to compare to
    percentageData.unshift(null); // This ensures the array length matches the tradingData length

    return percentageData;
  }

  // Function to calculate drawdown
  function calculateDrawdownChange(data) {
    const drawdownData = [];

    for (let i = 1; i < data.length; i++) {
      const previousBalance = data[i - 1].balance;
      const currentProfit = data[i].floatingPL;

      const drawdownChange = (currentProfit / previousBalance) * 100;

      if (drawdownChange > 0) {
        drawdownData.push(0);
      } else {
        drawdownData.push(Math.abs(drawdownChange));
      }
    }

    // Add a null or zero value for the first day since it doesn't have a previous day to compare to
    drawdownData.unshift(null); // This ensures the array length matches the tradingData length

    return drawdownData;
  }

  // Initialize chart on mount and when displayType changes
  useEffect(() => {
    if (tradingData && tradingData.length > 0) {
      if (!tradingData || tradingData.length === 0) {
        console.log("Trading data is not ready yet.");
        return;
      }

      const ctx = document.getElementById("ChartPNL").getContext("2d");

      // No need to parse tradingData since it's already an object/array
      const dates = tradingData.map((entry) => entry.date);
      const balances = tradingData.map((entry) => entry.balance);

      // Destroy the previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Chart data for different types
      const chartData = {
        labels: dates, // Use actual dates from API
        datasets: [],
      };

      let chartType = "line"; // Default to line chart

      switch (displayType) {
        case "normal": // Valeur totale du compte (€)
          chartData.datasets.push({
            label: "Valeur totale du compte (€)",
            data: balances, // Use actual balance data from API
            fill: false,
            borderColor: "rgb(247, 166, 0)",
            tension: 0.15,
            backgroundColor: "black",
            pointRadius: 1.5,
          });
          break;

        case "percentage": // Variation Journalière (%)
          chartData.datasets.push({
            label: "Variation Journalière (%)",
            data: calculateDailyPercentageChange(tradingData), // Replace with actual daily variation data
            fill: false,
            borderColor: "rgb(247, 166, 0)",
            tension: 0.15,
            backgroundColor: "black",
            pointRadius: 1.5,
          });
          break;

        case "cumulativePercentage": // Evolution de la balance (%)
          chartData.datasets.push({
            label: "Evolution de la balance (%)",
            data: tradingData.map((entry) => entry.growthEquity), // Replace with actual cumulative percentage data
            fill: false,
            borderColor: "rgb(247, 166, 0)",
            tension: 0.15,
            backgroundColor: "black",
            pointRadius: 1.5,
          });
          break;

        case "drawdown": // Drawdown (%), use bar chart
          chartType = "bar";
          chartData.datasets.push({
            label: "Drawdown (%)",
            data: calculateDrawdownChange(tradingData), // Replace with actual drawdown data
            fill: false,
            borderColor: "rgb(247, 166, 0)",
            tension: 0.15,
            backgroundColor: "rgb(247, 166, 0)",
            pointRadius: 1.5,
          });
          break;

        default:
          break;
      }

      // Create a new chart instance and store it in chartRef
      chartRef.current = new Chart(ctx, {
        type: chartType,
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: displayType === "normal" ? false : true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: function (context) {
                  const value = context.parsed.y;
                  const roundedValue = value.toFixed(2);
                  // const suffix = chartType === 'normal' ? getCurrencySymbol() : '%'; // this line needed but create fonction to get currency
                  const suffix = displayType === "normal" ? "€" : "%";
                  return `${roundedValue} ${suffix}`;
                },
              },
            },
          },
        },
      });
    }

    // Clean up the chart instance when the component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [tradingData, displayType]); // Re-run the chart creation when tradingData or displayType changes

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const elapsedDays = accountData
    ? getElapsedDays(accountData.creationDate)
    : 0;
  const averages = accountData
    ? calculateAverage(
        timeFrame,
        accountData.profit,
        elapsedDays,
        accountData.deposits
      )
    : { averageTransactions: 0, averagePnL: 0 };

  const styles = {
    containerFluid: {
      marginTop: "2.5%",
      marginLeft: "2.5%",
      marginRight: "2.5%",
      paddingBottom: "2%",
    },
    chartContainer: {
      height: "auto",
      minWidth: "525px",
      backgroundColor: "#edf2f7",
      border: "1px solid #ced4da",
      borderRadius: "8px",
    },
    chartContainer2: {
      position: "relative",
      width: "100%",
      maxWidth: "100%",
      height: isMobileView ? "50%" : "628px",
      minHeight: "200px",
      paddingBottom: "40px",
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "40px",
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "45px",
    },
    cardTitle: {
      marginRight: "auto",
      marginBottom: "40px",
      color: "#004aad",
    },
    formSelect: {
      marginTop: "-10px",
      width: "225px",
      outline: "none",
      border: "1px solid #ced4da",
    },
    statsBox1: {
      minWidth: "525px",
      minHeight: "260px",
      height: "28%",
      marginBottom: "15px",
      marginTop: isMobileView ? "15px" : "0",
      backgroundColor: "#edf2f7",
      borderRadius: "8px",
      border: "1px solid #ced4da",
    },
    statsBox2: {
      minWidth: "525px",
      minHeight: "230px",
      height: "25%",
      marginBottom: "15px",
      backgroundColor: "#edf2f7",
      borderRadius: "8px",
      border: "1px solid #ced4da",
    },
    statsBox3: {
      minWidth: "525px",
      minHeight: "250px",
      height: "27%",
      backgroundColor: "#edf2f7",
      borderRadius: "8px",
      border: "1px solid #ced4da",
    },
    text: {
      color: "black",
      display: "flex",
      justifyContent: "space-between", // Add space between key-value pairs
      marginLeft: "40px",
      marginRight: "40px",
      marginBottom: "6px",
      fontSize: "16px",
    },
    statsContent: {
      color: "#000000",
      fontSize: "1rem",
    },
    header: {
      color: "#004aad",
      marginTop: "30px",
      marginBottom: "10px",
      marginLeft: "40px",
      fontSize: "30px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between", // Center the buttons horizontally
      alignItems: "center", // Align the buttons in the middle vertically
      gap: "5%", // Space between the buttons
      paddingTop: "20px",
      marginLeft: "40px",
      marginRight: "40px",
    },
    button: {
      width: "150px",
      backgroundColor: "transparent",
      color: "#004aad",
      borderRadius: "5px",
      borderWidth: "1px",
      borderColor: "#004aad",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "16px",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#003380",
    },
  };

  return (
    <>
      <Header />
      <div style={styles.containerFluid}>
        <div className="row mx-4">
          {/* Chart Section */}
          <div className="col-md-8">
            <div style={styles.chartContainer}>
              <div style={styles.headerContainer}>
                <h1 style={styles.cardTitle}>Aperçu des actifs</h1>
                <select
                  id="displayType"
                  className="form-select"
                  value={displayType}
                  onChange={(e) => setDisplayType(e.target.value)}
                  style={styles.formSelect}
                >
                  <option value="normal">Valeur totale du compte (€)</option>
                  <option value="percentage">Variation Journalière (%)</option>
                  <option value="cumulativePercentage">
                    Evolution de la balance (%)
                  </option>
                  <option value="drawdown">Drawdown (%)</option>
                </select>
              </div>
              <div style={styles.chartContainer2}>
                <canvas id="ChartPNL"></canvas>
              </div>
            </div>
          </div>

          {/* Stats Section */}
          <div className="col-md-4">
            {/* General Stats */}
            <div className="col-12 mb-3">
              <div style={styles.statsBox1}>
                <h2 style={styles.header}>Statistiques générales</h2>
                <div style={styles.statsContent}>
                  <p style={styles.text}>
                    <span>Temps écoulé :</span>{" "}
                    <span>
                      {accountData
                        ? calculateElapsedTime(accountData.creationDate)
                        : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Retrait(s) :</span>{" "}
                    <span>
                      {accountData
                        ? `${accountData.withdrawals} €`
                        : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Dépôt(s) :</span>{" "}
                    <span>
                      {accountData ? `${accountData.deposits} €` : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Gain absolu :</span>{" "}
                    <span>
                      {accountData ? `${accountData.absGain}%` : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Bénéfice total :</span>{" "}
                    <span>
                      {accountData ? `${accountData.profit} €` : "Loading..."}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Trading Stats */}
            <div className="col-12 mb-3">
              <div style={styles.statsBox2}>
                <h2 style={styles.header}>Statistiques de trading</h2>
                <div style={styles.statsContent}>
                  <p style={styles.text}>
                    <span>Profit Factor :</span>{" "}
                    <span>
                      {accountData ? accountData.profitFactor : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Nombres de trades :</span>{" "}
                    <span>
                      {accountData ? tradingData.length : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Equité :</span>{" "}
                    <span>
                      {accountData ? `${accountData.equity} €` : "Loading..."}
                    </span>
                  </p>
                  <p style={styles.text}>
                    <span>Equité (%) :</span>{" "}
                    <span>
                      {accountData
                        ? `${accountData.equityPercent}%`
                        : "Loading..."}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* P&L Stats */}
            <div className="col-12">
              <div style={styles.statsBox3}>
                <h2 style={styles.header}>Transactions moyennes et P&L</h2>
                <div style={styles.statsContent}>
                  <p style={styles.text}>
                    <span>Transactions moyennes par {timeFrame} :</span>{" "}
                    <span>{averages.averageTransactions.toFixed(2)}</span>
                  </p>
                  <p style={styles.text}>
                    <span>P&L moyen par {timeFrame} :</span>{" "}
                    <span>{averages.averagePnL.toFixed(2)} %</span>
                  </p>
                </div>
                {/* Custom buttons without Bootstrap */}
                <div style={styles.buttonContainer}>
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor:
                        timeFrame === "jour" ? "#004aad" : "transparent",
                      color: timeFrame === "jour" ? "white" : "#004aad",
                      boxShadow:
                        timeFrame === "jour"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.boxShadow =
                        "0 4px 8px rgba(0, 0, 0, 0.2)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.boxShadow =
                        timeFrame === "jour"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none")
                    }
                    onClick={() => setTimeFrame("jour")}
                  >
                    Jour
                  </button>
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor:
                        timeFrame === "mois" ? "#004aad" : "transparent",
                      color: timeFrame === "mois" ? "white" : "#004aad",
                      boxShadow:
                        timeFrame === "mois"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.boxShadow =
                        "0 4px 8px rgba(0, 0, 0, 0.2)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.boxShadow =
                        timeFrame === "mois"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none")
                    }
                    onClick={() => setTimeFrame("mois")}
                  >
                    Mois
                  </button>
                  <button
                    style={{
                      ...styles.button,
                      backgroundColor:
                        timeFrame === "année" ? "#004aad" : "transparent",
                      color: timeFrame === "année" ? "white" : "#004aad",
                      boxShadow:
                        timeFrame === "année"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.boxShadow =
                        "0 4px 8px rgba(0, 0, 0, 0.2)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.boxShadow =
                        timeFrame === "année"
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                          : "none")
                    }
                    onClick={() => setTimeFrame("année")}
                  >
                    Année
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
